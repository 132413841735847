import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'bananflix',
    loadChildren: () => import('./bananflix/bananflix.module').then( m => m.BananflixPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'fundamentals',
    loadChildren: () => import('./fundamentals/fundamentals.module').then( m => m.FundamentalsPageModule)
  }, 
  {
    path: '',
    redirectTo: '/fundamentals',
    pathMatch: 'full'
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'balance',
    loadChildren: () => import('./balance/balance.module').then( m => m.BalancePageModule)
  },
  {
    path: 'banana-tips',
    loadChildren: () => import('./banana-tips/banana-tips.module').then( m => m.BananaTipsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Banana Tips', url: '/tips', icon: 'pencil' },
    { title: 'BananFlix', url: '/bananflix', icon: 'laptop' },
    { title: 'Fundamentals', url: '/fundamentals', icon: 'planet' },
    { title: 'Support (Covid)', url: '/support', icon: 'people' }
  ];
  constructor(private title: Title, private meta: Meta) {
    this.meta.addTags([
      {name: 'keywords', content: 'Plant based, nutrition, health, diet reset, vegan, anti ageing, reverse ageing'},
      {name: 'description', content: 'Gut first bodybuilding & nutrition project'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
